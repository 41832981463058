import { acceptHMRUpdate, defineStore } from 'pinia';
import { useBaseStore } from '@store';
import { nanoid } from 'nanoid';
import { get } from 'lodash';
import { CREATE_NEW_FORM, UPDATE_FORM } from '@modules/form/graphql/FormMutations';
import { GET_ACTIVE_FORMS_DROPDOWN, GET_FORM_BY_ID, GET_FORMS } from '@modules/form/graphql/FormQueries';
import clone from '@utils/useClone';
import useDialog from '@utils/useDialog';
import useNotify from '@utils/useNotify';
import { recursiveElementDescriptionSearch } from './utils/helpers';
import { AlertIcons } from '@/types/dialog';
import { Form } from '@/types/graphql';
import { UseRefHistoryRecord } from '@/types/app';
import { ConditionalField, CreateFormType, FormKitSchemaObject, NestedLocation } from '@/types/form';
import useGraphQL from '@utils/useGraphQL';
import { safeUnpack } from '@utils/helpers';

export const useFormStore = defineStore({
	id: 'Form',
	state: () => ({
		schema: <FormKitSchemaObject[]>[],
		originalSchema: <string>'[]',
		editingElementIndex: <number>-1,
		editingElementPath: <string | undefined>undefined,
		elementSettingsOpen: false,
		formSettingsOpen: false,
		formSettings: {},
		undoStack: <UseRefHistoryRecord<FormKitSchemaObject[]>[]>[],
		redoStack: <UseRefHistoryRecord<FormKitSchemaObject[]>[]>[],
		historyCapacity: <number>20,
		activeForm: <Form>{},
		activeSchemaFields: <ConditionalField[]>[],
		forms: <Form[]>[],
		formLoaded: false,
		loading: false,
	}),
	getters: {
		editingElementAddress: (state): string => {
			if (state.editingElementIndex != -1) {
				if (state.editingElementPath) {
					return state.editingElementPath + '[' + state.editingElementIndex + ']';
				}
				return '[' + state.editingElementIndex + ']';
			}

			return '';
		},
		editingElement: (state): FormKitSchemaObject => {
			if (state.editingElementIndex != -1) {
				let path;
				if (state.editingElementPath) {
					path = state.editingElementPath + '[' + state.editingElementIndex + ']';
				} else {
					path = '[' + state.editingElementIndex + ']';
				}
				return get(state.schema, path) as FormKitSchemaObject;
			}

			return <FormKitSchemaObject>{};
		},
		activeSchema: (state): FormKitSchemaObject[] => {
			return state.schema;
		},
		canUndo: (state): boolean => {
			return state.undoStack.length > 0;
		},
		canRedo: (state): boolean => {
			return state.redoStack.length > 0;
		},
		hasUnsaved: (state): boolean => {
			return state.originalSchema !== JSON.stringify(state.schema);
		},
	},
	actions: {
		getActiveSchemaFields() {
			let fieldDescriptions: ConditionalField[] = [];
			for (const element of this.activeSchema) {
				fieldDescriptions = [...fieldDescriptions, ...recursiveElementDescriptionSearch(element)];
			}
			this.activeSchemaFields = fieldDescriptions;
		},
		getEditingElement() {
			return get(this.schema, this.editingElementAddress);
		},
		async createNewForm(formData: CreateFormType) {
			this.setLoadingStart();
			const graphQlResult = await useGraphQL
				.mutate(CREATE_NEW_FORM, {
					input: formData,
				})
				.finally(() => {
					this.setLoadingFinished();
				});

			if (graphQlResult?.createForm) {
				return graphQlResult.createForm.id;
			}
		},
		async updateForm() {
			this.setLoadingStart();
			useGraphQL
				.mutate(UPDATE_FORM, {
					input: {
						id: this.activeForm.id,
						name: this.activeForm.name,
						reference_name: this.activeForm.reference_name,
						description: this.activeForm.description,
						type_id: this.activeForm.type_id,
						status_id: this.activeForm.status_id,
						schema: JSON.stringify(this.activeSchema),
						settings: this.formSettings ? JSON.stringify(this.formSettings) : JSON.stringify([]),
					},
				})
				.then((graphQlResult) => {
					if (graphQlResult?.updateForm) {
						this.$patch((state) => {
							state.activeForm = graphQlResult.updateForm as Form;
							state.schema = safeUnpack<FormKitSchemaObject[]>(graphQlResult?.updateForm?.current_version?.schema, []);
							state.originalSchema = graphQlResult?.updateForm?.current_version?.schema ?? '[]';
							state.formSettings = safeUnpack(graphQlResult?.updateForm?.settings, []);
						});
						useNotify.icon({ icon: AlertIcons.Success }).title('Form successfully updated').fire();
					}
				})
				.finally(() => {
					this.setLoadingFinished();
				});
		},
		async getForms(count = 30, page = 1) {
			this.setLoadingStart();
			const graphQlResult = await useGraphQL.query(
				GET_FORMS,
				{
					first: count,
					page: page,
				},
				{
					fetchPolicy: 'cache-and-network',
				},
			);
			if (graphQlResult?.getForms) {
				this.forms = graphQlResult.getForms.data;
			}
			this.setLoadingFinished();
		},
		async getActiveFormDropdown(count = 30, page = 1) {
			const graphQlResult = await useGraphQL.query(GET_ACTIVE_FORMS_DROPDOWN, {
				first: count,
				page: page,
			});

			if (graphQlResult?.getForms) {
				const formList = [];
				for (const form of graphQlResult.getForms.data) {
					formList.push({
						label: form.name + (form.description ? ' [' + form.description + ']' : ''),
						value: form.id,
					});
				}
				return formList;
			}

			return [];
		},
		async getFormById(formId: string) {
			this.setLoadingStart();
			const graphQlResult = await useGraphQL.query(
				GET_FORM_BY_ID,
				{
					form_id: formId,
				},
				{
					fetchPolicy: 'no-cache',
				},
			);

			if (graphQlResult?.getFormById) {
				this.$patch((state) => {
					state.activeForm = graphQlResult.getFormById as Form;
					state.schema = safeUnpack<FormKitSchemaObject[]>(graphQlResult?.getFormById?.current_version?.schema, []);
					state.originalSchema = graphQlResult?.getFormById?.current_version?.schema ?? '[]';
					state.formSettings = safeUnpack(graphQlResult?.getFormById?.settings, []);
					state.formLoaded = true;
				});
			}

			this.setLoadingFinished();
		},
		async resetFormStore() {
			this.$patch((state) => {
				state.schema = <FormKitSchemaObject[]>[];
				state.originalSchema = <string>'[]';
				state.editingElementIndex = <number>-1;
				state.editingElementPath = <string | undefined>undefined;
				state.elementSettingsOpen = false;
				state.formSettingsOpen = false;
				state.undoStack = <UseRefHistoryRecord<FormKitSchemaObject[]>[]>[];
				state.redoStack = <UseRefHistoryRecord<FormKitSchemaObject[]>[]>[];
				state.historyCapacity = <number>20;
				state.activeSchemaFields = [];
				state.activeForm = <Form>{};
				state.forms = <Form[]>[];
				state.loading = false;
			});
		},

		setLoadingStart() {
			const baseStore = useBaseStore();
			baseStore.setLoadingStart();
			this.loading = true;
		},
		setLoadingFinished() {
			const baseStore = useBaseStore();
			baseStore.setLoadingFinished();
			this.loading = false;
		},
		async openFormkitElementEditor(elementIndex: number, elementPath: string | undefined = undefined) {
			this.editingElementIndex = elementIndex;
			if (elementPath) {
				this.editingElementPath = elementPath;
			}
			if (this.editingElementPath != undefined && !elementPath) {
				this.editingElementPath = undefined;
			}
			this.elementSettingsOpen = true;
			this.formSettingsOpen = false;
			const baseStore = useBaseStore();
			baseStore.secondaryPanelOpen = true;
		},
		async closeFormkitElementEditor() {
			this.$patch((state) => {
				state.editingElementIndex = <number>-1;
				state.editingElementPath = <string | undefined>undefined;
				state.elementSettingsOpen = false;
			});
			const baseStore = useBaseStore();
			baseStore.secondaryPanelOpen = false;
		},
		async openFormSettingsEditor() {
			this.formSettingsOpen = true;
			this.elementSettingsOpen = false;
			const baseStore = useBaseStore();
			baseStore.secondaryPanelOpen = true;
		},
		async closeFormSettingsEditor() {
			this.formSettingsOpen = false;
			if (this.editingElementAddress) {
				this.elementSettingsOpen = true;
			} else {
				const baseStore = useBaseStore();
				baseStore.secondaryPanelOpen = false;
			}
		},
		async deleteElementFromActiveSchema(elementIndex: number, elementAddress: string | undefined = undefined) {
			let elementToDelete: number | string = elementIndex;
			if (elementIndex != -1) {
				elementToDelete = '[' + elementIndex + ']';
			}
			if (elementAddress) {
				elementToDelete = elementAddress;
				if (elementIndex != -1) {
					elementToDelete = elementAddress + '[' + elementIndex + ']';
				}
			}
			if (elementToDelete === this.editingElementAddress) {
				await this.closeFormkitElementEditor();
			}
			const newSchema = clone(this.schema);
			if (elementAddress) {
				get(newSchema, elementAddress).splice(elementIndex, 1);
			} else {
				newSchema.splice(elementIndex, 1);
			}
			await this.makeUpdateToActiveSchema(newSchema);
		},
		async addElementToActiveSchema(newElement: FormKitSchemaObject, atIndex: NestedLocation | number | null = null) {
			const newSchema = clone(this.schema);
			if (typeof atIndex === 'number') {
				newSchema.splice(atIndex, 0, newElement);
			} else if (atIndex === null) {
				newSchema.push(newElement);
			} else {
				//nested insertion
				get(newSchema, atIndex.elementAddress).splice(atIndex.insertAt ?? 0, 0, newElement);
			}
			await this.makeUpdateToActiveSchema(newSchema);
		},
		async clearActiveSchema() {
			useDialog
				.title('Are you sure you want to do that?')
				.confirm('Continuing with clear this form! note, this can still be undone using the undo button.')
				.then(async (result) => {
					if (result.isConfirmed) {
						await this.makeUpdateToActiveSchema([]);
					}
				});
		},
		async makeUpdateToActiveSchema(newSchema: FormKitSchemaObject[]) {
			this.undoStack.unshift({
				snapshot: this.schema,
				timestamp: +Date.now(),
			});

			this.schema = newSchema;

			if (this.historyCapacity && this.undoStack.length > this.historyCapacity) this.undoStack.splice(this.historyCapacity, Infinity);
			if (this.redoStack.length) this.redoStack.splice(0, this.redoStack.length);
		},
		async undo() {
			if (!this.canUndo) {
				return;
			}
			const state = this.undoStack.shift();

			if (state) {
				this.redoStack.unshift({
					snapshot: clone(this.schema),
					timestamp: +Date.now(),
				});

				this.schema = state.snapshot;
			}
		},
		async redo() {
			if (!this.canRedo) {
				return;
			}
			const state = this.redoStack.shift();

			if (state) {
				this.undoStack.unshift({
					snapshot: clone(this.schema),
					timestamp: +Date.now(),
				});
				this.schema = state.snapshot;
			}
		},
		addUniqueIdToNewElement(elementSchema: FormKitSchemaObject): FormKitSchemaObject {
			// we clone the element, so we aren't linking them and then sub in a new key value with a random nanoid()
			return Object.assign({}, clone(elementSchema), { key: nanoid() }) as FormKitSchemaObject;
		},
		async triggerSidePanelHasClosed() {
			await this.closeFormkitElementEditor();
			await this.closeFormSettingsEditor();
		},
		getDropdownList() {
			return this.getActiveFormDropdown();
		},
		async getSelectedDropdownOption(formId: string) {
			for (const formDropdownOption of await this.getActiveFormDropdown()) {
				if (formDropdownOption.value === formId) {
					return formDropdownOption;
				}
			}
		},
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useFormStore, import.meta.hot));
}

import gql from 'graphql-tag';

export const CREATE_NEW_WORKFLOW = gql`
	mutation Mutation($input: CreateWorkflowInput!) {
		createWorkflow(input: $input) {
			id
		}
	}
`;

export const UPDATE_WORKFLOW = gql`
	mutation Mutation($input: UpdateWorkflowInput!) {
		updateWorkflow(input: $input) {
			id
			status_id
			settings
			name
			reference_name
			description
			current_revision_id
			current_revision_replaces
			current_revision_at
			current_revision_by
			current_revision {
				id
				workflow_id
				revision
				schema
				forms
				triggers
				variables
				exits
				created_at
				created_by
				updated_at
				updated_by
			}
			revision_history
			published_at
			published_by
			archived_at
			archived_by
			created_at
			created_by
			updated_at
			updated_by
		}
	}
`;

export const DELETE_WORKFLOW = gql`
	mutation Mutation($input: DeleteWorkflowInput!) {
		deleteWorkflow(input: $input)
	}
`;

export const TRASH_WORKFLOW = gql`
	mutation Mutation($input: TrashWorkflowInput!) {
		trashWorkflow(input: $input)
	}
`;
